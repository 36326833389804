@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');

.website-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
}

.website-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: #FFFFFF;
    padding: 40px 20%;
}

.website-header-left {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.demo-container {
    display: flex;
    flex-direction: row;

}

.selling-point::before {
    content: "✔ ︎";
}

.website-header-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.website-header-image {
    max-width: 25vw;

}

.website-centered-segment {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    text-align: center;
    padding: 40px 25%;
}

.website-footer {
    background-color: #FFFFFF;
    padding: 40px 25%;
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 100%;
    justify-content: flex-start;
}

.website-footer-column {
    display: flex;
    flex-direction: column;
}

.website-footer a {
    text-decoration: none;
    color: #1b1b1b;
}

.website-main-content {
    border-radius: 100px;
    background-color: #edf5fc;
    width: 95%;
}

.handwritten {
    font-family: 'Shadows Into Light', cursive !important;
    font-size: 28px !important;
}

.website-news-segment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 100%;
    padding: 20px 20%;
}

.website-per-centered {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 20px;
}

.website-nav-bar {
    background-color: #FFF;
    height: 70px;
    align-items: center;
    width: 100%;
    padding: 0px 20%;
    display: flex;
    flex-direction: row;
    /*gap: 15px;*/
    justify-content: space-between;
}

.website-nav-items {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 60%;
}

.website-centered-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    /*background-color: #edf5fc;*/
    padding: 40px;
    border-radius: 50px 50px 0 0;
}

.website-header-video {
    margin-top: 20px;
    width: 60%;
    cursor: pointer;
    max-width: 800px;
    min-width: 800px;
    border: 1px #8b8b8b solid;
}

.play-button {
    position: absolute;
    margin: auto;
    top: calc(50% - 30px);
    font-size: 60px !important;
    color: #FFF !important;
    background-color: #1b1b1b;
    cursor: pointer;
}

.nav-item-landing {
    text-decoration: none;
    cursor: pointer;
    color: #000;
}

.nav-item-landing:hover {
    font-weight: bold;
}

.nav-item:hover {
    font-weight: bold;
}

.full-screen {
    position: absolute;
    left: 765px;
    top: calc(100% - 35px);
    cursor: pointer;
    color: #FFF !important;
}

@media only screen and (max-width: 1300px) {
    .website-footer {
        padding: 40px 15%;
    }

    .website-centered-segment {
        padding: 40px 15%;
    }

    .website-header {
        padding: 40px 15%;
    }

    .website-news-segment {
        padding: 40px 15%;
    }
}

@media only screen and (max-width: 1100px) {
    .website-news-segment {
        flex-direction: column;
    }

    .nav-item-landing {
        width: 95px;
    }

    .website-nav-bar {
        padding: 0 5%;
    }

}


@media only screen and (max-width: 768px) {

    .full-screen {
        left: calc(90vw - 35px);
    }

    .nav-item-landing {
        display: none;
    }

    .website-header-video {
        min-width: 90vw;
        max-width: 90vw;
    }

    .website-news-segment {
        padding: 40px 5%;
        flex-direction: column;
        align-items: center;

    }

    .website-header {
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 40px 5%;
    }

    .website-header-left {
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
    }

    .website-header-image {
        max-width: 80vw;
    }

    .website-header-right {
        width: 100%;
    }

    .website-footer {
        padding: 20px 5%;
    }

    .website-centered-segment {
        padding: 20px 5%;
    }

    .website-main-content {
        border-radius: 50px;
        padding-top: 20px;
        padding-bottom: 20px;

    }

    .website-per-centered {
        flex-direction: column;
    }

    .website-footer {
        flex-direction: column;
        align-items: center;
    }

    .website-footer-column {
        align-items: center;
    }

    .website-nav-bar {
        padding: 20px 5%;
    }


}
