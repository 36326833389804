.course-submit-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 70%;
}

.recommended-courses-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
    gap: 15px;
    margin-bottom: 60px;
}

@media only screen and (max-width: 900px) {
    .course-submit-container {
        width: 90%;
        flex-direction: column;
        padding-bottom: 200px;
    }

}