.application-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
    width: 100%;
    padding: 20px 15%;
}

.application-status-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    gap: 10px;
}

.apply-card {
    width: 32%;
    min-height: 40%;
}

@media only screen and (max-width: 1200px) {
    .application-container {
        padding: 20px 10%;
    }
}

@media only screen and (max-width: 1000px) {
    .application-container {
        padding: 20px 5%;
    }
}


@media only screen and (max-width: 900px) {
    .application-status-container {
        flex-direction: column;
        padding-bottom: 80px;
    }

    .apply-card {
        width: 100%;
    }
}
