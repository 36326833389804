.auth-main {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.auth-video {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    left: 0px;
    top: 0px;
    filter: blur(2px) brightness(0.6);
    background-repeat: no-repeat;
    object-fit: cover;
    transform: scale(1.01);
}
