.schools-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #EDF5FC;
    padding-top: 40px;  
    padding-bottom: 80px;
    height: 100%;
    gap: 20px;
}

.school-container {
    background-color: #EDF5FC;
    min-height: 100%;
    min-width: 100%;
    display: flex;
    padding-top: 20px;
    padding-bottom: 60px;
    flex-direction: column;
    align-items: center;

}

.school-title-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    vertical-align: center;
    line-height: 30px;
}

.course-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px;
    gap: 20px;
    width: 100%;
    padding-bottom: 80px;
    /*overflow-y: auto;*/
    /*max-height: 600px;*/
}

.chip-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    align-self: flex-end;
}

.courses-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-top: 40px;
    width: 100%;
    background-color: #EDF5FC;
    min-height: 100%;
    padding-bottom: 60px;
}

.courses-filter-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
}

@media only screen and (max-width: 900px) {
    .chip-container {
        flex-wrap: wrap;
        justify-content: normal;
    }

    .course-container {
        padding: 0px 0px 60px;
    }
}
