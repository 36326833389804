.color-button {
    transition: all ease-in-out 0.5s;
}

.course-overview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 80px;
    gap: 20px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
}

.modal-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: #00000099;
}

.goal-overlay {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: fixed;
    background-color: #00000088;
    z-index: 99999;
}