.mobile-nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 60px;
    width: 100vw;
    align-items: center;
    position: fixed !important;
    bottom: 0;
    background-color: #272D2D;
    border-radius: 5px 5px 0 0;
    border-top: 1px solid #272D2D !important;
    z-index: 10;
}

.web-nav {
    top: 0;
    border-radius: 0;
    height: 70px;
    padding-left: 15%;
    padding-right: 15%;
    justify-content: space-between;
    align-items: center;
    gap: 80px;
    border-top: 0 !important;
    background-color: #FFFFFF !important;
    margin-bottom: 60px;
}

.pop-button{
    filter: drop-shadow(0 0 0.1rem rgb(47, 120, 255));
}

.pop-button:hover{
    filter: "";
}

.mobile-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #FFFFFF !important;
}

.web-nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #272D2D !important;
}

.nav-active {
    padding: 10px;
    border-radius: 10px;
    background-color: #EEE;
}

.mobile-nav-icon {
    color: #fff !important;
}

.mobile-nav-item:hover {
    color: #b7b7b7;
}

.mobile-nav-item:hover .mobile-nav-icon {
    color: #b7b7b7 !important;; /* Change icon color on hover */
}

@media only screen and (max-width: 1300px) {
    .web-nav {
        gap: 20px;
    }
}
