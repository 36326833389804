.home-container {
    display: flex;
    flex-direction: column;
    background-color: #EDF5FC;
    height: 100%;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

.home-header {
    display: flex;
    flex-direction: row;
    height: 400px;
    max-width: 100%;
    padding: 40px 15%;

}

.home-header-left {
    width: 50%;
    display: flex;
    padding: 4%;
    flex-direction: column;
}

.home-header-right {
    width: 50%;
}

.goal-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 40px;
    gap: 20px;
}

@media only screen and (max-width: 900px) {
    .home-header {
        flex-direction: column;
        align-items: center;
        height: auto;
        text-align: center;
    }

    .home-header-left {
        width: 100%;
    }

    .goal-container {
        display: flex;
        flex-direction: row;
        padding: 40px 0;
        overflow-y: hidden;
        width: 90vw;
        justify-content: space-evenly;
        /*flex-direction: column;*/
    }

    .home-header-right {
        width: 100%;
    }
}
