.dashboard-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
    padding: 40px;
}

.dashboard-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 60px;
    width: 100%;
}